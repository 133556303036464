import React from "react"

import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Header showChildren={false} />
    <SEO
      description="The Jeffrey Epstein Ranking provides a classification of Universities according to the donations received via the scientific broker Jeffrey Epstein."
      title="Jeffrey Epstein Ranking of University funding"
    />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
